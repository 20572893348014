import React, { PureComponent } from "react"
import { Row } from 'react-bootstrap';
import RaisedButton from './rasied-button';
import CoachingBanner from '../images/coaching-banner.png';

export default class Mentorship extends PureComponent {
  render() {
    const { defaultContentStyles, stretchRowStyles } = this.props;
    return (
      <div style={defaultContentStyles} className='coaching'>
        <Row className='intro-banner' style={{
          ...stretchRowStyles,
          backgroundImage: `url(${CoachingBanner})`,
        }}>
          <div className='banner-text'>
            <p>
              Personal Coaching
            </p>
            <p>
              with Dr. Toni Warner
            </p>
          </div>
        </Row>
        <Row className='coaching-text-section header-section italic-section'>
          <p>
            Looking like an outward success, but feeling like an inward mess?
          </p>
          <p>
            On paper, you’re mostly successful and you “should” feel happy. You’re good at what you do and you’re an achiever.
          </p>
          <p>
            Even though you outwardly look put together, inside you just know there’s something more, something missing or something “off”.
          </p>
          <p>
            You want to know why and you want to fix it.
          </p>
        </Row>
        <Row className='coaching-cta'>
          <h2>Ready To Make A Change?</h2>
          <div>
            <RaisedButton href='https://forms.gle/jnZ9SwYi61qVF1Z39'>
              Schedule A Consultation Now!
            </RaisedButton>
          </div>
        </Row>
        <Row className='coaching-text-section'>
          <p>
            Most of my clients are pretty hard on themselves. They are their own worst critic, but they are reliable, responsible, and strong. They’re often a “fixer” and they are used to getting things done.
          </p>
          <p className='p-header'>
            They want to:
          </p>
          <ul className='assorted-list'>
            <li>
              Feel fulfilled
            </li>
            <li>
              Be happier
            </li>
            <li>
              Have more free time
            </li>
            <li>
              Be more present
            </li>
            <li>
              Know they’re making a difference
            </li>
            <li>
              Get more out of life 
            </li>
          </ul>
          <p className='p-header'>
            But, they are struggling with:
          </p>
          <ul className='assorted-list'>
            <li>
              Exhaustion
            </li>
            <li>
              Frustration
            </li>
            <li>
              Stress
            </li>
            <li>
              Anxiety
            </li>
            <li>
              Doubt
            </li>
            <li>
              Finding Time
            </li>
          </ul>
        </Row>
        <Row className='coaching-text-section primary-section' >
          <p>
            Working together on a one-to-one basis allows for the highest level of support available to you, in a private and customized fashion. This level coaching package puts me in your back pocket for convenient access and guidance the entire time and includes:
          </p>
          <ul>
            <li>
              Regular 1:1 coaching sessions
            </li>
            <li>
              24/7 access to my signature coaching course
            </li>
            <li>
              Individualized support between sessions
            </li>
            <li>
              Exclusive supportive e-materials 
            </li>
            <li>
              High quality resources and relevant recommendations to suit your needs and goals
            </li>
          </ul>
          <p>
            Let’s get one thing clear, an investment in high-level coaching is an investment in your own health, but even more beyond that too, because an optimized self means an optimized life- at work and at home. It means improved relationships, greater happiness and satisfaction with your life, and more fulfillment and clarity with the direction you’re moving in.
          </p>
        </Row>
        <Row className='coaching-text-section'>
          <h2>Take The First Step Towards Change</h2>
          <div className='cta-button'>
            <RaisedButton href='https://forms.gle/jnZ9SwYi61qVF1Z39'>
              Schedule A Consultation
            </RaisedButton>
          </div>
          <div className='italic-section'>
            <p>
              Quality personal development AUTOMATICALLY fosters professional development
            </p>
            <p>
              Life enhancement IS leadership enhancement
            </p>
            <p>
              Self-growth ALWAYS impacts relationship growth
            </p>
          </div>
        </Row>
      </div>
    )
  }
}
