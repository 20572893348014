import React from 'react';

const RaisedButton = ({ children, onClick, href, target }) => {
  return (
    <div className='intro-raised-button-wrapper' >
      {
        href ? (
          <a className='raised-button' href={href} target={target}>
            {children}
          </a>
        ) : (
          <button className='raised-button' onClick={onClick}>
            {children}
          </button>
        )
      }
    </div>
  );
}

export default RaisedButton;