import React, { PureComponent } from "react"
import Layout from '../components/layout';
import Mentorship from '../components/mentorship';

export default class MentorshipPage extends PureComponent {
  render() {
    return (
      <Layout>
        <Mentorship/>
      </Layout>
    )
  }
}

